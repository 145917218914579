<template>
  <div>
    <p class="mb-3">
      Determine which of the small molecules below fit each of the three descriptions given in the
      table. Note: molecules can fit more than one description and there can be more than one
      molecule per description.
    </p>

    <p class="pl-7">
      <chemical-latex content="C2, O2-, CN-, O2, CO, C2^2-" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">
              <stemble-latex content="$\textbf{Description}$" />
            </th>
            <th style="font-size: 15px">
              <chemical-latex content="C2" />
            </th>
            <th style="font-size: 15px">
              <chemical-latex content="O2-" />
            </th>
            <th style="font-size: 15px">
              <chemical-latex content="CN-" />
            </th>
            <th style="font-size: 15px">
              <chemical-latex content="O2" />
            </th>
            <th style="font-size: 15px">
              <chemical-latex content="CO" />
            </th>
            <th style="font-size: 15px">
              <chemical-latex content="C2^2-" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Bond-order of 3}$" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.tripleBonds" value="1" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.tripleBonds" value="2" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.tripleBonds" value="3" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.tripleBonds" value="4" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.tripleBonds" value="5" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.tripleBonds" value="6" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Paramagnetic}$" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.paramagnetic" value="1" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.paramagnetic" value="2" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.paramagnetic" value="3" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.paramagnetic" value="4" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.paramagnetic" value="5" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.paramagnetic" value="6" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Half-integer bond-order}$" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.halfIntegerBondOrder" value="1" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.halfIntegerBondOrder" value="2" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.halfIntegerBondOrder" value="3" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.halfIntegerBondOrder" value="4" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.halfIntegerBondOrder" value="5" />
            </td>
            <td style="text-align: center">
              <v-checkbox v-model="inputs.halfIntegerBondOrder" value="6" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question243e',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        tripleBonds: [],
        paramagnetic: [],
        halfIntegerBondOrder: [],
      },
    };
  },
};
</script>
